import React from 'react';
import { Box, Card, CardContent, Container, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography, Collapse } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getImageSets } from '../api/imageSet';
import ImageSetRow from '../components/ImageSetRow';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { getStatistics } from '../api/statistics';

const Home: React.FC = () => {
  const [mainExpanded, setMainExpanded] = React.useState(() => {
    const stored = localStorage.getItem('main-content-expanded');
    return stored ? JSON.parse(stored) : true;
  });

  const handleMainToggle = () => {
    const newState = !mainExpanded;
    setMainExpanded(newState);
    localStorage.setItem('main-content-expanded', JSON.stringify(newState));
  };

  const { data: latestImageData } = useQuery({
    queryKey: ['latestImage'],
    queryFn: () => getImageSets({ limit: 1 }),
  });

  const { data: finnishImages } = useQuery({
    queryKey: ['latestImages', 'finnish'],
    queryFn: () => getImageSets({
      limit: 3,
      countries: ['Suomi']
    }),
  });

  const { data: wpImages } = useQuery({
    queryKey: ['latestImages', 'wp'],
    queryFn: () => getImageSets({
      limit: 3,
      wp: true
    }),
  });

  const { data: worldImages } = useQuery({
    queryKey: ['latestImages', 'world'],
    queryFn: () => getImageSets({ limit: 3, wp: false, fin: false }),
  });

  const { data: stats, isLoading: statsLoading, error: statsError } = useQuery({
    queryKey: ['statistics'],
    queryFn: getStatistics
  });

  const backgroundImage = latestImageData?.results[0]?.images[0]?.image || '/images/background.jpg';

  const formatDate = (dateString: string | undefined) => {
    if (!dateString) return 'Ei havaintoja';
    const date = new Date(dateString);
    return date.toLocaleDateString('fi-FI');
  };

  const ImageSetSection = ({ title, data }: { title: string, data: typeof finnishImages }) => {
    const storageKey = `section-${title.replace(/\s+/g, '-').toLowerCase()}-expanded`;
    const [isExpanded, setIsExpanded] = React.useState(() => {
      const stored = localStorage.getItem(storageKey);
      return stored ? JSON.parse(stored) : true;
    });

    const handleToggle = () => {
      const newState = !isExpanded;
      setIsExpanded(newState);
      localStorage.setItem(storageKey, JSON.stringify(newState));
    };

    return (
      <Box sx={{ mb: 4, px: 2 }}>
        <Box
          onClick={handleToggle}
          sx={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            mb: 2
          }}
        >
          {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          <Typography variant="h6" component="div">
            {title}
          </Typography>
        </Box>
        <Collapse in={isExpanded}>
          {!data?.results.length ? (
            <Typography fontWeight="Bold" color="text.secondary">Ei kuvia</Typography>
          ) : (
            <Grid container spacing={2} direction="column">
              {data.results.map(imageSet => (
                <Grid item key={imageSet.id}>
                  <ImageSetRow imageSet={imageSet} />
                </Grid>
              ))}
            </Grid>
          )}
        </Collapse>
      </Box>
    );
  };

  return (
    <Box>
      <Box sx={{
        height: { xs: '200px', sm: '300px' },
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }} />

      <Container maxWidth="lg" sx={{ mt: { xs: -4, sm: -6, md: -8 } }}>
        <Card sx={{ p: { xs: 2, sm: 3 } }} elevation={3}>
          <Box onClick={handleMainToggle} sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center', mb: 2 }}>
            {mainExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            <Typography paddingLeft={2} variant="h5" component="div">Ajankohtaista</Typography>
          </Box>

          <Collapse in={mainExpanded}>
            <Grid container spacing={{ xs: 2, md: 4 }}>
              <Grid item xs={12} md={8}>
                <Typography paragraph>
                  Tämä lintuhavaintojen esittelypalvelu tarjoaa liki 40 000 kuvaa useista tuhansista havainnoista ja lähes kaikista Suomessa ja lähialueille havaituista lajeista. Kuvien joukossa on kokeneiden kuvaajien mestariteoksia ja kaikista pohjoisimmista kuvattujen lajien havainnointiolosuhteista on. Suomen jälkeen WP-alueelta, satunnaisesti havaintoja yritetään niiden laadukkaasta tasolta. Myös mp3-muotoisia äänitteitä löytyy sivustolta runsaasti valikoima.
                </Typography>
                <Typography paragraph>
                  Sivuston uudistumiseen tähtäävä rahoituskampanja on päättynyt onnistuneesti. Yhteensä 234 yksityishenkilöä saatiin kasaan hiukset 9770 euroa, mikä ylittää tavoitteen komeasti! Tuhummin kiitokset kaikille kirjoittamiselle! Uuden ja uljan tarsiger.fi:n kehitystyö on jo aloitettu ja valmista odotetaan tänä syksynä! käyttöön ja hajanvuoden aikana.
                </Typography>
                <Typography>
                  Tarsiger.com toivottaa kaikille hyviä lintuhetkiä!
                </Typography>
              </Grid>

              <Grid item xs={12} md={4}>
                <Box sx={{ overflowX: 'auto' }}>
                  <Table size="small" sx={{ mb: 3, minWidth: { xs: 300, sm: 'auto' } }}>
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="right">Kuvia</TableCell>
                        <TableCell align="right">Lajeja</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {statsLoading ? (
                        <TableRow>
                          <TableCell colSpan={3} align="center">Ladataan...</TableCell>
                        </TableRow>
                      ) : statsError ? (
                        <TableRow>
                          <TableCell colSpan={3} align="center">Virhe ladattaessa tilastoja</TableCell>
                        </TableRow>
                      ) : (
                        <>
                          <TableRow>
                            <TableCell>Suomi</TableCell>
                            <TableCell align="right">{stats?.images.finland || 0}</TableCell>
                            <TableCell align="right">{stats?.species.finland || 0}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>WP</TableCell>
                            <TableCell align="right">{stats?.images.wp || 0}</TableCell>
                            <TableCell align="right">{stats?.species.wp || 0}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Maailma</TableCell>
                            <TableCell align="right">{stats?.images.world || 0}</TableCell>
                            <TableCell align="right">{stats?.species.world || 0}</TableCell>
                          </TableRow>
                        </>
                      )}
                    </TableBody>
                  </Table>
                </Box>

                <Typography variant="subtitle2" gutterBottom>
                  Uusimmat havainnot
                </Typography>
                <Box sx={{ overflowX: 'auto' }}>
                  <Table size="small" sx={{ minWidth: { xs: 300, sm: 'auto' } }}>
                    <TableBody>
                      {statsLoading ? (
                        <TableRow>
                          <TableCell colSpan={2} align="center">Ladataan...</TableCell>
                        </TableRow>
                      ) : statsError ? (
                        <TableRow>
                          <TableCell colSpan={2} align="center">Virhe ladattaessa havaintoja</TableCell>
                        </TableRow>
                      ) : (
                        <>
                          <TableRow>
                            <TableCell>Suomi:</TableCell>
                            <TableCell align="right">
                              {stats?.latest_observations.finland ?
                                formatDate(stats.latest_observations.finland.created_at) :
                                'Ei havaintoja'
                              }
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>WP:</TableCell>
                            <TableCell align="right">
                              {stats?.latest_observations.wp ?
                                formatDate(stats.latest_observations.wp.created_at) :
                                'Ei havaintoja'
                              }
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Maailma:</TableCell>
                            <TableCell align="right">
                              {stats?.latest_observations.world ?
                                formatDate(stats.latest_observations.world.created_at) :
                                'Ei havaintoja'
                              }
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                    </TableBody>
                  </Table>
                </Box>
              </Grid>

              <Grid container item spacing={2}>
                {[1, 2, 3, 4, 5].map((sponsor) => (
                  <Grid item xs={6} sm={2.4} key={sponsor}>
                    <Box sx={{
                      bgcolor: 'grey.300',
                      height: { xs: 50, sm: 60 },
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: 1
                    }}>
                      <Typography>LOGO</Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Collapse>
        </Card>

        <Box sx={{ mt: 4, overflow: 'hidden' }}>
          <ImageSetSection title="Uusimmat kuvat Suomesta" data={finnishImages} />
          <ImageSetSection title="Uusimmat kuvat WP-alueelta" data={wpImages} />
          <ImageSetSection title="Uusimmat kuvat maailmalta" data={worldImages} />
        </Box>
      </Container>
    </Box>
  );
};

export default Home;